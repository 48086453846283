import { GiPriceTag } from "react-icons/gi";
import { fareCodes } from "./faresTokens";
import { inclusionCodes } from "./inclusionsTokens";
import { defineType } from "sanity";

export default defineType({
  name: "farePage",
  title: "Fare Page",
  type: "document",
  icon: GiPriceTag,
  preview: {
    select: {
      title: "title.en",
      media: "heroImage"
    },
    prepare({ title, media }) {
      return {
        title,
        media
      };
    }
  },
  fieldsets: [
    {
      name: "infographic",
      title: "Infographic Section",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  groups: [
    { name: "asCard", title: "Render as Card" },
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "fareCode",
      title: "Fare Code",
      type: "string",
      options: {
        list: fareCodes
      }
    },
    {
      name: "fareCodes",
      title: "Fare Codes",
      type: "array",
      of: [
        {
          type: "string"
        }
      ],
      options: {
        list: fareCodes
      }
    },
    {
      name: "heroImage",
      title: "Hero Image",
      type: "imageWithAlt",
      options: { hotspot: true },
      validation: (Rule) => Rule.required()
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "headline",
      title: "Headline",
      description: "for instance: PEACE OF MIND - ALL INCLUSIVE",
      type: "multilingualString"
    },
    {
      name: "description",
      title: "Description",
      type: "multilingualArrayBlockContent",
      validation: (Rule) => Rule.required()
    },
    {
      name: "hideFYCInNonAirCountries",
      title: "Hide FYC in non-air countries",
      type: "boolean"
    },
    {
      name: "enabledInclusions",
      type: "array",
      of: [{ type: "string" }],
      options: {
        list: inclusionCodes
      },
      validation: (Rule) => Rule.required(),
      fieldset: "infographic"
    },
    {
      name: "infographicServicesDisclaimer",
      title: "Services disclaimer",
      type: "multilingualArrayBlockContent",
      fieldset: "infographic"
    },
    {
      name: "infographicFooterNotes",
      title: "Footer notes",
      type: "multilingualArrayBlockContent",
      fieldset: "infographic"
    },
    {
      name: "infographicFooterNotesForNonAirCountries",
      title: "Footer disclaimer for non-air countries",
      type: "multilingualArrayBlockContent",
      fieldset: "infographic"
    },
    {
      name: "refundCreditExchangeFooterNotes",
      title: "Refund Credit Exchange footer notes",
      type: "multilingualArrayBlockContent",
      fieldset: "infographic"
    },
    {
      name: "featuredPromo",
      title: "Featured Promo",
      type: "array",
      validation: (Rule) => Rule.max(1),
      of: [
        {
          type: "reference",
          to: [{ type: "exclusiveOfferPerPromo" }]
        }
      ]
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: "metadata",
      group: "seo"
    },
    {
      name: "card",
      title: "Render as Card",
      type: "asCard",
      group: "asCard"
    }
  ]
});
