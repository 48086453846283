import { defineType } from "sanity";
import { GiTeacher } from "react-icons/gi";

export default defineType({
  title: "7. Expedition Team Section",
  name: "expeditionTeamSection",
  icon: GiTeacher,
  type: "object",
  fields: [
    {
      name: "heading",
      title: "Heading",
      type: "sectionTitle"
    },
    {
      name: "expeditionTeam",
      title: "Expedition Team",
      type: "array",
      validation: (Rule) => Rule.required(),
      of: [
        {
          type: "reference",
          to: [{ type: "expeditionTeamMember" }]
        }
      ]
    },
    {
      name: "destination",
      title: "Destination",
      type: "reference",
      to: [{ type: "destination" }]
    }
  ],
  preview: {
    prepare(_document) {
      return {
        title: "Expedition Team Section"
      };
    }
  }
});
