import { FaMoneyBill } from "react-icons/fa";
import { filterableMultilingualBlockContent } from "../base";
import { metadataWithoutParent } from "../page-fragments";
import filterableNumber from "../base/filterableNumber";
import { cruiseGroups } from "../data-types/cruiseGroups";
import { defineType } from "sanity";

export default defineType({
  name: "cruiseGroupPerFare",
  title: "Cruise Group by Fare",
  type: "document",
  icon: FaMoneyBill,
  preview: {
    select: {
      title: "title",
      media: "heroImage",
      cruiseGroup: "cruiseGroup"
    },
    prepare: ({ title, media, cruiseGroup }) => ({
      //@ts-ignore
      title: filterableMultilingualBlockContent.toString(title[0]),
      media,
      subtitle: cruiseGroup
    })
  },
  fieldsets: [
    {
      name: "features",
      title: "Features",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "visibility",
      title: "Visibility",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "cruises",
      title: "Cruises",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  groups: [
    {
      name: "termsAndConditions",
      title: "Terms&Conditions"
    },
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "cruiseGroup",
      title: "Cruise Group",
      type: "string",
      validation: (Rule) => Rule.required(),
      options: {
        list: cruiseGroups
      }
    },
    {
      name: "customTokens",
      title: "Custom Tokens",
      group: "termsAndConditions",
      type: "array",
      of: [
        {
          title: "Currency Token",
          type: "currencyToken"
        },
        {
          title: "Date Token",
          type: "dateToken"
        }
      ]
    },
    {
      name: "order",
      title: "Order",
      group: "termsAndConditions",
      description:
        "in ascending order (1: first in the list, 100000: last in the list)",
      type: "array",
      of: [
        {
          type: filterableNumber.name
        }
      ]
    },
    {
      name: "termsAndConditions",
      title: "Terms & Conditions",
      group: "termsAndConditions",
      type: "array",
      of: [
        {
          type: filterableMultilingualBlockContent.name
        }
      ]
    },
    {
      name: "fareCode",
      title: "Fare Code",
      type: "string",
      validation: (Rule) => Rule.required(),
      options: {
        list: [
          { title: "Door To Door", value: "DoorToDoor" },
          { title: "Air Included", value: "AirIncluded" },
          { title: "Port To Port", value: "PortToPort" }
        ]
      }
    },
    {
      name: "title",
      title: "Title",
      type: "array",
      of: [{ type: filterableMultilingualBlockContent.name }],
      validation: (Rule) => Rule.required()
    },

    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: metadataWithoutParent.name,
      group: "seo"
    }
  ]
});
