/* eslint-disable react/default-props-match-prop-types */
import React, { useCallback, useId, useMemo } from "react";
import {
  Autocomplete,
  BaseAutocompleteOption,
  Box,
  Card,
  Container,
  Flex,
  Inline,
  Stack,
  Text
} from "@sanity/ui";
import { StringInputProps, set, unset } from "sanity";
import { SearchIcon } from "@sanity/icons";
import icons, { IconsId } from "@silversea-ssc/design-system-icons";
import styled from "styled-components";

const DesignSystemIconInput: React.FC<StringInputProps> = ({
  value: currentValue,
  onChange: originalOnChange
}) => {
  const inputId = useId();

  const onChange = useCallback(
    (value: string) => {
      if (!value || value.length == 0) originalOnChange(unset());
    },
    [originalOnChange]
  );

  const onSelect = useCallback(
    (value: string) => {
      originalOnChange(set(value));
    },
    [originalOnChange]
  );

  const renderValue = useCallback(
    (value: string, option: BaseAutocompleteOption | undefined): string => {
      return value;
    },
    []
  );

  console.debug("icons", icons);

  const iconOptions = Object.keys(icons).map((i) => ({
    value: i,
    label: i
  }));

  return (
    <Container>
      <Autocomplete
        fontSize={[2, 2, 3]}
        icon={SearchIcon}
        id={inputId}
        options={iconOptions}
        placeholder={currentValue ? currentValue : "Type to search..."}
        value={currentValue}
        onSelect={onSelect}
        onChange={onChange}
        renderOption={(item) => {
          return (
            <Card as="button">
              <Flex align="center">
                <Box flex={1} padding={3}>
                  <Stack space={3}>
                    <Inline>
                      <Box paddingRight={2}>
                        <DesignSystemIcon name={item.value} />
                      </Box>
                      <Text size={2}>{item.label}</Text>
                    </Inline>
                  </Stack>
                </Box>
              </Flex>
            </Card>
          );
        }}
        // custom value render function
        renderValue={renderValue}
      />
    </Container>
  );
};

export default DesignSystemIconInput;

const IconComponent = styled("i")`
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;

  &:before {
    font-family: ds-icons !important;
    content: attr(data-icon-code);
  }
`;

export interface IconProps {
  name: string;
}

const codePointFromIconName = (name: string) => {
  const icon = parseInt(icons[name as IconsId], 10);
  if (!icon) return null;
  return String.fromCharCode(icon).slice(-1);
};

const DesignSystemIcon: React.FC<IconProps> = ({ name }) => {
  const id = useId(); // needed to fix react render issue (sometimes was showing a "?")
  const iconFontCode = useMemo(() => codePointFromIconName(name), [name]);
  if (!iconFontCode) return null;

  return <IconComponent key={id} data-icon-code={iconFontCode}></IconComponent>;
};

export const designSystemIconPreview = (icon: string) =>
  icon ? <DesignSystemIcon name={icon} /> : <div>No icon</div>;
