import { useState, useEffect } from "react";
import { ArrayOfObjectsInputProps, useClient } from "sanity";

const AsyncCruiseDepartureDateYearList: React.FC<ArrayOfObjectsInputProps> = (
  props
) => {
  const sanityClient = useClient({ apiVersion: "2023-01-01" });
  const [listItems, setListItems] = useState<string[]>([]);
  const { schemaType, renderDefault, value } = props;
  const { options } = schemaType;

  const sartOfTheYear = new Date().getFullYear() + "-01-01";
  const allValidYearsQuery = `array::unique(
  *[_type == "cruiseData" && departureDate >= "${sartOfTheYear}"]|order(departureYearMonth)
{
  "year": string::split(departureYearMonth, "-")[0]
}.year
)`;

  useEffect(() => {
    const getYears = async () => {
      const years = await sanityClient.fetch<string[]>(allValidYearsQuery);
      const yearsWithValue = Array.from(new Set([...years, ...(value || [])]))
        .filter((x): x is string => !!x)
        .sort((a, b) => a.localeCompare(b));
      setListItems(yearsWithValue);
    };
    getYears();
  }, [allValidYearsQuery, sanityClient, value]);

  return renderDefault({
    ...props,
    schemaType: { ...schemaType, options: { ...options, list: listItems } }
  });
};

export default AsyncCruiseDepartureDateYearList;
