import { FaSearchDollar } from "react-icons/fa";
import { cruiseGroups } from "../data-types/cruiseGroups";
import { fareCodes } from "../data-types/faresTokens";
import { defineType } from "sanity";
import AsyncCruiseDepartureDateYearList from "../../components/AsyncCruiseDepartureDateYearList";

export default defineType({
  title: "4. Find Your Cruise",
  name: "fyc",
  type: "object",
  icon: FaSearchDollar,
  fieldsets: [
    {
      name: "filters",
      title: "Set filters",
      options: { collapsible: true, collapsed: true }
    }
  ],
  fields: [
    {
      name: "title",
      title: "Title",
      type: "multilingualString"
    },
    {
      name: "showDestinationFilter",
      title: "Show destination filter",
      type: "boolean"
    },
    {
      name: "showShipFilter",
      title: "Show ship filter",
      type: "boolean"
    },
    {
      name: "showExpeditionFilter",
      title: "Show expedition filter",
      type: "boolean"
    },
    {
      name: "showPromosFilter",
      title: "Show promos filter",
      type: "boolean"
    },
    {
      name: "showFeaturesFilter",
      title: "Show features filter",
      type: "boolean"
    },
    {
      name: "showLastSeenProducts",
      title: "Show last seen products",
      type: "boolean"
    },
    {
      fieldset: "filters",
      name: "filterByShip",
      title: "Filter by Ship",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "ship" }]
        }
      ]
    },
    {
      fieldset: "filters",
      name: "filterByDestination",
      title: "Filter by Destination",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "destination" }]
        }
      ]
    },
    {
      fieldset: "filters",
      name: "filterByCruiseGroups",
      title: "Filter by Cruise Groups",
      type: "array",
      of: [
        {
          type: "string"
        }
      ],
      options: {
        list: cruiseGroups
      }
    },
    {
      fieldset: "filters",
      name: "filterByFareCodes",
      title: "Filter by Fares",
      type: "array",
      of: [
        {
          type: "string"
        }
      ],
      options: {
        list: fareCodes
      }
    },
    {
      fieldset: "filters",
      name: "filterByOffer",
      title: "Filter by Offer",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "exclusiveOfferPerPromo" }]
        }
      ]
    },
    {
      fieldset: "filters",
      name: "filterByCruiseType",
      title: "Filter by Cruise Type",
      description: "Leave empty for all",
      type: "array",
      of: [{ type: "string" }],
      options: {
        list: [
          { title: "Classic", value: "Classic" },
          { title: "Expedition", value: "Expedition" }
        ]
      }
    },
    {
      fieldset: "filters",
      name: "filterByComboType",
      title: "Filter by Combo Type",
      description: "Leave empty for all",
      type: "array",
      of: [{ type: "string" }],
      options: {
        list: [
          { title: "Single", value: "Single" },
          { title: "Combo", value: "Combo" },
          { title: "World", value: "World" },
          { title: "Grand", value: "Grand" }
        ]
      }
    },
    {
      fieldset: "filters",
      name: "filterByCruise",
      title: "Filter by Cruise",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "cruise" }, { type: "specialVoyage" }],
          weak: true
        }
      ]
    },
    {
      fieldset: "filters",
      name: "filterByCruiseCodes",
      title: "Filter by Cruise Codes",
      description: "comma separated cruise codes",
      type: "text"
    },
    {
      fieldset: "filters",
      name: "filterByCruisesLaunch",
      title: "Filter by Cruises Launch",
      type: "reference",
      to: [{ type: "cruisesLaunch" }],
      weak: true
    },
    {
      fieldset: "filters",
      name: "filterByCountries",
      title: "Filter by Countries",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "country" }]
        }
      ]
    },
    {
      fieldset: "filters",
      name: "filterByPorts",
      title: "Filter by Ports",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "port" }]
        }
      ]
    },
    {
      fieldset: "filters",
      name: "filterByFeatures",
      title: "Filter by Features",
      description: "Leave empty for all",
      type: "array",
      of: [{ type: "string" }],
      options: {
        list: [
          { title: "Bridge Sailings", value: "BridgeSailings" },
          { title: "Gentlemen Hosts", value: "GentlemenHosts" },
          { title: "Culinary Voyages", value: "CulinaryVoyages" },
          { title: "Wine Cruises", value: "WineCruises" }
        ]
      }
    },
    {
      fieldset: "filters",
      name: "filterByLoadStress",
      title: "Filter by Load Stress",
      type: "array",
      of: [{ type: "string" }],
      options: {
        list: ["Good", "Average", "Bad", "Last Minute", "Distress"]
      }
    },
    {
      fieldset: "filters",
      name: "filterByDepartureYears",
      title: "Filter by Departure Years",
      type: "array",
      of: [{ type: "string" }],
      components: {
        input: AsyncCruiseDepartureDateYearList
      }
    }
  ],
  preview: {
    select: {
      title: "heading.title.en"
    },
    prepare(selection) {
      const { title } = selection;
      return {
        title: title || "(Find Your Cruise)"
      };
    }
  }
});
