import { FaShip } from "react-icons/fa";
import { metadataWithoutParent } from "../page-fragments";
import { multilingualUrl } from "../base";
import brochureGrouping from "./brochureGrouping";
import shipVersion from "./shipVersion";
import { defineField, defineType } from "sanity";

export default defineType({
  name: "ship",
  title: "Ship",
  type: "document",
  icon: FaShip,
  orderings: [
    {
      title: "Name",
      name: "nameAsc",
      by: [{ field: "name.en", direction: "asc" }]
    }
  ],
  preview: {
    select: {
      title: "name",
      shopApiCode: "shopApiCode",
      image: "card.image"
    },
    prepare(value) {
      return Object.assign({}, value, {
        title: value.title,
        subtitle: `Code: ${value.shopApiCode || "none"}`,
        media: value.image
      });
    }
  },
  fieldsets: [
    {
      name: "shopApi",
      title: "Shop API fields"
    }
  ],
  groups: [
    { name: "asCard", title: "Render as Card" },
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "name",
      title: "Name",
      type: "string",
      validation: (Rule) => Rule.required()
    },
    {
      name: "isVisible",
      title: "Visible?",
      description: "True if visible to the public website",
      type: "boolean"
    },
    {
      name: "shipType",
      title: "Type",
      type: "string",
      options: {
        list: [
          { title: "Classic", value: "Classic" },
          { title: "Expedition", value: "Expedition" }
        ]
      },
      validation: (Rule) => Rule.required()
    },
    defineField({
      name: "versions",
      title: "Versions",
      type: "array",
      of: [{ type: shipVersion.name }]
      // options: { // TODO: reenable
      //   canDuplicate: "validFrom",
      //   editModal: "fullscreen"
      // }
    }),
    {
      name: "newVersionContent",
      title: "New version content",
      type: "multilingualText",
      options: {
        collapsable: true
      }
    },
    {
      name: "brochureGroup",
      title: "Brochure Group",

      type: "reference",
      to: [{ type: brochureGrouping.name }]
    },
    {
      name: "card",
      title: "Render as Card",
      type: "asCard",
      group: "asCard"
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: metadataWithoutParent.name,
      group: "seo"
    },
    {
      name: "covidProcedureLink",
      title: "COVID Procedure Link",
      type: multilingualUrl.name
    },
    {
      name: "shopApiCode",
      title: "Shop API Code",
      fieldset: "shopApi",
      type: "string",
      validation: (Rule) => Rule.length(2)
    }
  ]
});
