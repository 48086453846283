import { FaFileContract } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType({
  name: "termsAndConditionsSummary",
  title: "Terms & Conditions Summary",
  type: "document",
  icon: FaFileContract,
  fieldsets: [
    {
      name: "content",
      title: "Content",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "modal",
      title: "Modal",
      options: { collapsible: true, collapsed: true }
    }
  ],
  fields: [
    {
      name: "title",
      title: "Title",
      type: "multilingualArrayString",
      fieldset: "content"
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualArrayBlockContent",
      fieldset: "content"
    },
    {
      name: "modalTitle",
      title: "Modal Title",
      type: "multilingualArrayString",
      fieldset: "modal"
    },
    {
      name: "modalContent",
      title: "Modal Content",
      type: "multilingualArrayBlockContent",
      fieldset: "modal"
    }
  ],
  preview: {
    prepare(value) {
      return {
        title: "Terms & Conditions Summary"
      };
    }
  }
});
